
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    @Component
    export default class SearchStatus extends Vue {
      private status: string = ''
      private options: any=[
        {
          value:'1',
          label:'激活'
        },{
          value:'0',
          label:'未激活',
        }
      ]

      @Emit('getStatus')
       sendStatus(status:number){}
    }
